import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Col, Row } from "react-bootstrap";
import { StaticPageContainer } from "components/docs/shared-components/PageContainers";
import DocsHelmet from "components/docs/shared-components/DocsHelmet";
import HeaderBar from "components/docs/layout/HeaderBar";
import { useWorkflows } from "hooks/useWorkflows";
import FeaturedUseCases from "./FeaturedUsecases";
import RelatedUseCase from "templates/docs/marketing/components/RelatedUseCase";
import SuggestedSearchBar from "components/docs/integrations/SuggestedSearchBar";
import { APICategory } from "@merge-api/merge-javascript-shared";
import {
  abbreviationForAPICategory,
  apiCategoryFromString,
  displayNameForAPICategory,
} from "components/docs/utils/services";
import { transformDocsSlugToTitle } from "components/docs/utils/DocumentationUtils";
import { UseCaseWorkflowType } from "../../types/UseCaseWorkflowTypes";
import { MERGE_DEMO_PAGE } from "components/docs/navigation/links";
import { SingleValue } from "react-select";
import SubmitUseCaseRequest from "./SubmitUseCaseRequest";
import DocumentationSection from "components/docs/DocumentationSection";
import DeprecatedH2 from "components/deprecated/DeprecatedH2";

const TitleContainer = styled.div`
  gap: 8px;
`;

const DropdownContainer = styled(Row)`
  row-gap: 20px;
`;

interface DropdownOption {
  value: string;
  label: string;
}

const getCategoryDropDownOptions = () => {
  const categoryOptions: any[] = [];
  for (const category in APICategory) {
    if (category !== APICategory.mktg) {
      categoryOptions.push({
        value: abbreviationForAPICategory(category as APICategory),
        label: displayNameForAPICategory(category as APICategory) || "",
      });
    }
  }
  return categoryOptions;
};

const getIndustryDropDownOptions = (allWorkflows: UseCaseWorkflowType[]) => {
  const industryOptions: DropdownOption[] = [];
  const industriesSeen = new Set();

  allWorkflows.forEach((workflow) => {
    const { industries } = workflow.use_case;
    industries.forEach((industry) => {
      if (!industriesSeen.has(industry.name)) {
        industryOptions.push({
          value: industry.name,
          label: industry.name,
        });
        industriesSeen.add(industry.name);
      }
    });
  });
  return industryOptions;
};

const getDataModelOptions = (allWorkflows: UseCaseWorkflowType[]) => {
  const dataModelOptions: { value: string; label: string }[] = [];
  const dataModelsSeen = new Set();

  allWorkflows.forEach((workflow) => {
    const { use_case_models: useCaseModels } = workflow.use_case;

    useCaseModels.forEach((useCaseModel) => {
      if (
        useCaseModel?.common_model_docs_slug &&
        !dataModelsSeen.has(useCaseModel.common_model_docs_slug)
      ) {
        dataModelOptions.push({
          value: useCaseModel.common_model_docs_slug,
          label: transformDocsSlugToTitle(useCaseModel.common_model_docs_slug),
        });
        dataModelsSeen.add(useCaseModel.common_model_docs_slug);
      }
    });
  });
  return dataModelOptions;
};

const UseCaseOverview = () => {
  const [categorySelected, setSelectedCategory] =
    useState<SingleValue<DropdownOption | undefined>>(undefined);
  const [industrySelected, setSelectedIndustry] =
    useState<SingleValue<DropdownOption | undefined>>(undefined);
  const [dataModelSelected, setSelectedDataModel] =
    useState<SingleValue<DropdownOption | undefined>>(undefined);
  const { featuredWorkflows, renderedWorkflows, allWorkflows } = useWorkflows(
    categorySelected,
    industrySelected,
    dataModelSelected,
  );

  const isFiltering: boolean =
    categorySelected != null || industrySelected != null || dataModelSelected != null;

  const categoryDropdownOptions = getCategoryDropDownOptions();
  const industryDropdownOptions = getIndustryDropDownOptions(allWorkflows);
  const dataModelOptions = getDataModelOptions(allWorkflows);

  const updateUrl = (category?: string, industry?: string, dataModel?: string) => {
    const params = new URLSearchParams();

    if (category) {
      params.set("category", category);
    }

    if (dataModel) {
      const [parsedCategory, parsedDataModel] = dataModel.trim().split("/");
      if (!category) {
        params.set("category", parsedCategory);
      }
      params.set("dataModel", parsedDataModel);
    }

    if (industry) {
      params.set("industry", industry);
    }

    window.history.pushState(
      null,
      "",
      `${window.location.pathname}${
        category || dataModel || industry ? `?${params.toString()}` : ""
      }`,
    );
  };

  const handleCategoryChange = (selectedCategory: SingleValue<DropdownOption> | undefined) => {
    setSelectedCategory(selectedCategory);
    updateUrl(selectedCategory?.value, industrySelected?.value, dataModelSelected?.value);
  };

  const handleIndustryChange = (selectedIndustry: SingleValue<DropdownOption> | undefined) => {
    setSelectedIndustry(selectedIndustry);
    updateUrl(categorySelected?.value, selectedIndustry?.value, dataModelSelected?.value);
  };

  const handleDataModelChange = (selectedDataModel: SingleValue<DropdownOption> | undefined) => {
    setSelectedDataModel(selectedDataModel);
    updateUrl(categorySelected?.value, industrySelected?.value, selectedDataModel?.value);
  };

  const getFilteredDataModelOptions = (
    dataModelOptions: DropdownOption[],
    categorySelected?: string,
  ) => {
    if (!categorySelected) {
      return dataModelOptions;
    }
    const newDataModelOptions: DropdownOption[] = [];
    dataModelOptions.forEach((dataModelOption) => {
      const category = apiCategoryFromString(dataModelOption.value.split("/")[0]);
      if (!category) {
        return;
      }
      const dataModelOptionCategory = abbreviationForAPICategory(category);
      if (dataModelOptionCategory === categorySelected) {
        newDataModelOptions.push(dataModelOption);
      }
    });
    return newDataModelOptions;
  };

  const getFilteredCategoryOptions = (
    categoryOptions: DropdownOption[],
    dataModelSelected?: string,
  ) => {
    if (!dataModelSelected) {
      return categoryOptions;
    }
    const newCategoryOptions: DropdownOption[] = [];
    categoryOptions.forEach((categoryOption) => {
      const category = apiCategoryFromString(dataModelSelected.split("/")[0]);
      if (!category) {
        return;
      }
      const dataModelOptionCategory = abbreviationForAPICategory(category);
      if (dataModelOptionCategory === categoryOption?.value) {
        newCategoryOptions.push(categoryOption);
      }
    });
    return newCategoryOptions;
  };

  const filteredDataModelOptions = getFilteredDataModelOptions(
    dataModelOptions,
    categorySelected?.value,
  );

  const filteredCategoryOptions = getFilteredCategoryOptions(
    categoryDropdownOptions,
    dataModelSelected?.value,
  );

  const title = "Use cases with Merge";
  const description =
    "Use these high-level walkthroughs to understand how your product works with Merge.";

  useEffect(() => {
    if (typeof window !== "undefined") {
      const params = new URLSearchParams(window.location.search);
      const queryCategory = params.get("category");
      let queryDataModel = params.get("dataModel");
      queryDataModel = queryCategory
        ? queryCategory.trim().toLowerCase() + "/" + queryDataModel + "/"
        : queryDataModel;
      const queryIndustry = params.get("industry");

      if (queryCategory) {
        const matchingCategory = getCategoryDropDownOptions().find(
          (option) => option.value === queryCategory.toUpperCase(),
        );

        if (matchingCategory) {
          setSelectedCategory(matchingCategory);
        }
      }

      if (queryDataModel) {
        const matchingDataModel = getDataModelOptions(allWorkflows).find(
          (option) => option.value === queryDataModel,
        );

        if (matchingDataModel) {
          setSelectedDataModel(matchingDataModel);
        }
      }

      if (queryIndustry) {
        const matchingIndustry = getIndustryDropDownOptions(allWorkflows).find(
          (option) => option.value === queryIndustry,
        );

        if (matchingIndustry) {
          setSelectedIndustry(matchingIndustry);
        }
      }
    }
  }, []);

  return (
    <StaticPageContainer lessPadding={true}>
      <DocsHelmet title={title} description={description} />
      <HeaderBar title={title} subtitle={description} />
      <DocumentationSection title="Find your use case">
        <p>
          Filter by key attributes to find the use case that matches your data needs, industry, or
          category.
        </p>
        <p>
          Don’t see what you’re looking for? We probably support it.{" "}
          <a href={MERGE_DEMO_PAGE} target="_blank">
            Reach out for a demo.
          </a>
        </p>
      </DocumentationSection>
      <DropdownContainer className="mt-4 d-flex">
        <Col xs={12} md={4} className="pl-3 pr-2.5">
          <SuggestedSearchBar
            placeholder={"Select category"}
            value={categorySelected === undefined ? undefined : categorySelected}
            dropDownOptions={filteredCategoryOptions}
            onChange={(selectedCategoryValue) => {
              handleCategoryChange(selectedCategoryValue);
            }}
          />
        </Col>
        <Col xs={12} md={4} className="pl-2.5 pr-2.5">
          <SuggestedSearchBar
            placeholder={"Select data model"}
            value={dataModelSelected === undefined ? undefined : dataModelSelected}
            dropDownOptions={filteredDataModelOptions}
            onChange={(selectedDataModel) => {
              handleDataModelChange(selectedDataModel);
            }}
          />
        </Col>
        <Col xs={12} md={4} className="pr-3 pl-2.5">
          <SuggestedSearchBar
            placeholder={"Select industry"}
            dropDownOptions={industryDropdownOptions}
            value={industrySelected === undefined ? undefined : industrySelected}
            onChange={(industrySelected) => {
              handleIndustryChange(industrySelected);
            }}
          />
        </Col>
      </DropdownContainer>
      {!isFiltering ? <FeaturedUseCases featuredUseCases={featuredWorkflows} /> : <></>}
      <div className="mt-12">
        {!isFiltering && <DeprecatedH2>Explore use cases</DeprecatedH2>}
        {renderedWorkflows.length > 0 ? (
          <div className="mt-6 -mr-3 d-flex flex-wrap">
            {renderedWorkflows.map((useCase: UseCaseWorkflowType) => {
              const { use_case, workflow_slug } = useCase;
              return (
                <RelatedUseCase
                  useCase={use_case}
                  workflowSlug={workflow_slug}
                  showSubCategories={true}
                />
              );
            })}
          </div>
        ) : (
          <SubmitUseCaseRequest />
        )}
      </div>
    </StaticPageContainer>
  );
};

export default UseCaseOverview;
